<template>
  <b-card>
    <b-row align-h="between" align-v="center">
      <b-col class="mb-1" cols="12" sm="6" md="5" lg="4">
        <v-select
          v-if="company.role.name !== 'Client' && !exports"
          class="selectExample mt-0 select-size-sm"
          v-model="client_id"
          :options="itemsOptions"
          label="Client"
          :getOptionLabel="(item) => item.client_name"
          :reduce="(item) => item"
          placeholder="Selecione o cliente"
        >
          <template v-slot:option="item">
            <div class="select-responsible">
              <div>
                {{
                  item.client_name != 'Todos'
                    ? `nome: ${item.client_name}`
                    : item.client_name
                }}
                <br />
                {{
                  item.client_name != 'Todos'
                    ? `Doc: ${
                        item.cnpj
                          ? item.cnpj
                          : 'Num de Documento não cadastrado'
                      }`
                    : ''
                }}
              </div>
            </div>
          </template>
        </v-select>
      </b-col>

      <b-col cols="12" sm="4" md="3" lg="2" align="end">
        <b-button
          v-if="company.role.name !== 'Client'"
          class="btn-sm btn-danger"
          @click="showNewPDFModal = true"
        >
          Novo PDF
        </b-button>
      </b-col>

      <b-col cols="12">
        <b-tabs>
          <b-tab title="Gerados" @click="setExtern(false)">
            <b-table
              :busy="isBusy"
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="0"
              stacked="md"
              ref="mytab"
              :key="tableKey"
              show-empty
              small
              @filtered="onFiltered"
            >
              <template #cell(name)="row">
                {{ row.value.first }} {{ row.value.last }}
              </template>

              <template #cell(actions)="row">
                <img
                  :id="`icon-pdf-img-${row.index}`"
                  class="cursor-pointer"
                  @click="openDocument(row.item.path)"
                  src="@/assets/images/icons/file-icons/icon-pdf.png"
                />

                <b-tooltip
                  triggers="hover"
                  placement="topright"
                  :target="`icon-pdf-img-${row.index}`"
                  :title="'Baixar PDF'"
                  :delay="{ show: 750, hide: 50 }"
                />
              </template>

              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>

              <template #row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">
                      {{ key }}: {{ value }}
                    </li>
                  </ul>
                </b-card>
              </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

            </b-table>

            <b-row class="d-flex justify-content-center">
              <b-col sm="5" md="4" class="my-1 mt-5">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                  @change="getPage"
                ></b-pagination>
                <!-- @input="
              customPagination ? getCustomPaginationPage(currentPage) : ''
            " -->
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="setExtern(true)" title="Externos">
            <b-row v-show="externalPdfGroup.expand == false">
              <b-col
                v-if="company.role.name !== 'Client'"
                cols="12"
                sm="6"
                md="4"
                lg="2"
                xl="1"
              >
                <b-card
                  overlay
                  @click="externalPdfGroup.modal_new = true"
                  align="center"
                  class="effects"
                  img-src="@/assets/images/icons/folder_plus.svg"
                  style="
                    background-color: rgba(0, 0, 0, 0.05);
                    border: 3px dotted;
                    color: rgba(0, 0, 0, 0.3);
                  "
                >
                  <div>
                    <small><strong>Adicionar</strong></small>
                  </div>
                </b-card>
              </b-col>

              <b-col cols="12" sm="6" md="4" lg="2" xl="1">
                <b-card
                  overlay
                  align="center"
                  class="effects group_selected"
                  id="folderall"
                  @click="handleGroupSelect('all')"
                  @dblclick="handleGroupOpen('all')"
                  img-src="@/assets/images/icons/folder.svg"
                  style="background-color: rgba(0, 0, 0, 0.05)"
                >
                  <div>
                    <small><strong>Todos</strong></small>
                  </div>
                </b-card>
              </b-col>

              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
                xl="1"
                v-for="(item, i) in externalPdfGroup.array"
                :key="i"
              >
                <b-card
                  overlay
                  align="center"
                  class="effects"
                  :id="'folder' + i"
                  @click="handleGroupSelect(i)"
                  @dblclick="handleGroupOpen(i)"
                  img-src="@/assets/images/icons/folder.svg"
                  style="background-color: rgba(0, 0, 0, 0.05)"
                >
                  <div>
                    <small
                      ><strong>{{ item.description }}</strong></small
                    >
                  </div>
                  <!-- <div style="position: absolute; right: 0;left: 0;bottom: 0;">
                    <feather-icon
                      icon="TrashIcon"
                      size="15"
                      color="red"
                    />
                  </div> -->
                </b-card>
              </b-col>
            </b-row>

            <b-row v-show="externalPdfGroup.expand == true">
              <b-col cols="8" style="display: flex">
                <b-button-group>
                  <b-button variant="outline-dark" size="sm" disabled>
                    <h5>
                      <strong
                        >Grupo:
                        {{
                          externalPdfGroup.selected_id == 'all'
                            ? 'Todos'
                            : externalPdfGroup.selected.description
                        }}</strong
                      >
                    </h5>
                  </b-button>
                  <b-button
                    v-if="
                      externalPdfGroup.selected_id != 'all' &&
                      company.role.name !== 'Client'
                    "
                    @click="updatePdfGroup(false)"
                    variant="outline-dark"
                    size="sm"
                  >
                    <feather-icon icon="EditIcon" size="15" />
                  </b-button>
                  <b-button
                    v-if="
                      externalPdfGroup.selected_id != 'all' &&
                      company.role.name !== 'Client'
                    "
                    @click="removePdfGroup(false)"
                    variant="outline-dark"
                    size="sm"
                  >
                    <feather-icon icon="TrashIcon" size="15" color="red" />
                  </b-button>
                </b-button-group>
              </b-col>
              <b-col cols="4" align="end">
                <div></div>
                <b-button
                  @click="externalPdfGroup.expand = false"
                  variant="outline-dark"
                  size="sm"
                >
                  <h5><feather-icon icon="SkipBackIcon" size="15" /> voltar</h5>
                </b-button>
              </b-col>
            </b-row>
            <vx-table
              v-show="externalPdfGroup.expand == true"
              ref="externalPdfTable"
              class="mx-0 px-0 mt-1"
              :fields="fields"
              :items="externalItems"
              :showDelete="true"
              :showGroup="true"
              @delete-document="deleteExternalPdf"
              @group-document="changeExternalPdfGroup"
              type="documents"
              :customPagination="true"
              :customPaginationCall="'getAllExternalPdfs'"
              :customPaginationCallObject="{
                client_id: client_user ? client_user.client_id : '',
                company_id: company.id,
                pdf_group_id:
                  externalPdfGroup.selected_id == 'all'
                    ? ''
                    : externalPdfGroup.selected.id,
                perPage: 10,
              }"
            />
          </b-tab>
        </b-tabs>
      </b-col>

      <b-modal
        v-model="externalPdfGroup.modal_update"
        @hide="externalPdfGroup.description = ''"
        centered
      >
        <template #modal-header><h5>Atualizar Grupo</h5></template>

        <template #default>
          <label class="mt-1"><small>Nome</small></label>
          <b-form-input
            v-model="externalPdfGroup.description"
            placeholder="Digite aqui..."
          ></b-form-input>
          <b-row class="mt-2" align-h="center">
            <b-col cols="6" xl="4">
              <b-card
                overlay
                align="center"
                img-src="@/assets/images/icons/folder.svg"
                style="background-color: rgba(0, 0, 0, 0.05)"
              >
                <div>
                  <small
                    ><strong>{{ externalPdfGroup.description }}</strong></small
                  >
                </div>
              </b-card>
            </b-col>
          </b-row>
        </template>

        <template #modal-footer="{ cancel }">
          <b-button variant="outline-secondary" @click="cancel()"
            >Fechar</b-button
          >
          <b-button
            @click="updatePdfGroup(true)"
            :disabled="externalPdfGroup.description == '' || loading"
            variant="success"
          >
            Atualizar
            <feather-icon
              v-if="loading"
              icon="LoaderIcon"
              size="15"
              class="spinner"
            />
          </b-button>
        </template>
      </b-modal>

      <b-modal
        v-model="externalPdfGroup.modal_new"
        @hide="externalPdfGroup.description = ''"
        centered
      >
        <template #modal-header>
          <h5>Novo Grupo</h5>
        </template>

        <template #default>
          <label class="mt-1"><small>Nome</small></label>
          <b-form-input
            v-model="externalPdfGroup.description"
            placeholder="Digite aqui..."
          ></b-form-input>
          <b-row class="mt-2" align-h="center">
            <b-col cols="6" xl="4">
              <b-card
                overlay
                align="center"
                img-src="@/assets/images/icons/folder.svg"
                style="background-color: rgba(0, 0, 0, 0.05)"
              >
                <div>
                  <small
                    ><strong>{{ externalPdfGroup.description }}</strong></small
                  >
                </div>
              </b-card>
            </b-col>
          </b-row>
        </template>

        <template #modal-footer="{ cancel }">
          <b-button variant="outline-secondary" @click="cancel()"
            >Fechar</b-button
          >
          <b-button
            @click="savePdfGroup"
            :disabled="externalPdfGroup.description == '' || loading"
            variant="success"
          >
            Cadastrar
            <feather-icon
              v-if="loading"
              icon="LoaderIcon"
              size="15"
              class="spinner"
            />
          </b-button>
        </template>
      </b-modal>

      <b-modal
        v-model="showNewPDFModal"
        @hide="handleNewPDFModalClose"
        id="modal-scoped"
        centered
      >
        <template #modal-header>
          <h5>Novo PDF Externo</h5>
        </template>

        <template #default>
          <label><small>Cliente</small></label>

          <v-select
            v-model="modelNewPDF.client_id"
            :options="clientsOptions"
            label="Client"
            :getOptionLabel="(item) => item.name"
            placeholder="Selecione o cliente..."
            @input="getClientEquipments"
            :reduce="(option) => option.id"
          >
            <template v-slot:option="item">
              <div class="select-responsible">
                <div>
                  {{ item.name != 'Todos' ? `nome: ${item.name}` : item.name }}
                  <br />
                  {{
                    item.name != 'Todos'
                      ? `Doc: ${
                          item.num_doc
                            ? item.num_doc
                            : 'Num de Documento não cadastrado'
                        }`
                      : ''
                  }}
                </div>
              </div>
            </template>
          </v-select>

          <label class="mt-1"><small>Arquivo PDF</small></label>
          <b-form-file
            @input="handleExternalPdfSelected"
            v-model="modelNewPDF.file"
            ref="file-input"
            accept="application/pdf"
            browse-text="Procurar"
            placeholder="Arquivo não selecionado"
            drop-placeholder="Solte arquivos aqui"
            :disabled="!modelNewPDF.client_id"
          ></b-form-file>

          <label class="mt-1"><small>Descrição (opcional)</small></label>
          <b-form-input
            v-model="modelNewPDF.description"
            placeholder="Digite aqui..."
            :disabled="!modelNewPDF.client_id"
          ></b-form-input>

          <label class="mt-1"><small>Equipamento (opcional)</small></label>
          <v-select
            v-model="modelNewPDF.equipment_id"
            :options="equipmentOptions"
            label="description"
            placeholder="Selecione o equipamento..."
            :disabled="!modelNewPDF.client_id || equipmentLoading"
            :reduce="(option) => option.id"
          />

          <label class="mt-1"><small>Grupo (opcional)</small></label>
          <v-select
            v-model="modelNewPDF.pdf_group_id"
            :options="externalPdfGroup.array"
            label="description"
            placeholder="Selecione o grupo..."
            :disabled="!modelNewPDF.client_id"
            :reduce="(option) => option.id"
          />

          <div class="card mt-1">
            <div class="card-body py-1">
              <b-row align-h="center" align-v="center">
                <b-col cols="2" align="center">
                  <feather-icon size="28" icon="AlertCircleIcon" />
                </b-col>
                <b-col cols="10">
                  <p class="text-justify small">
                    <i
                      >Atenção! Ao criar um PDF externo você irá selecionar e
                      disponibilizar um arquivo externo em formato PDF, podendo
                      apenas vincula-lo a um equipamento.</i
                    >
                  </p>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>

        <template #modal-footer="{ cancel }">
          <b-button variant="outline-secondary" @click="cancel()">
            Fechar
          </b-button>
          <b-button
            variant="success"
            @click="insertExternalPDF"
            :disabled="!modelNewPDF.client_id || !modelNewPDF.file || loading"
          >
            Cadastrar
            <feather-icon
              v-if="loading"
              icon="LoaderIcon"
              size="15"
              class="spinner"
            />
          </b-button>
        </template>
      </b-modal>

      <b-modal
        v-model="externalPdfGroup.change_group.modal"
        @hide="deleteExternalPdfId = null"
        no-close-on-backdrop
        centered
      >
        <template #modal-header><h5>Mover Documento para grupo</h5></template>

        <template #default>
          <small>Selecione o <b class="text-primary">grupo destino</b></small>
          <v-select
            v-model="externalPdfGroup.change_group.group_id"
            :options="[
              { description: 'Sem grupo', id: 'none' },
              ...externalPdfGroup.array,
            ]"
            label="description"
            placeholder="Selecione o grupo..."
            :reduce="(option) => option.id"
          />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button variant="outline-secondary" @click="cancel()"
            >Fechar</b-button
          >
          <b-button
            variant="primary"
            :disabled="loading || !externalPdfGroup.change_group.group_id"
            @click="
              changeExternalPdfGroup(
                externalPdfGroup.change_group.external_pdf_id,
                true
              )
            "
          >
            Mover
            <feather-icon
              v-if="loading"
              icon="LoaderIcon"
              size="15"
              class="spinner"
            />
          </b-button>
        </template>
      </b-modal>

      <b-modal v-model="externalPdfGroup.show_delete" centered>
        <template #modal-header><h5>Excluir Grupo</h5></template>

        <template #default>
          Tem certeza que deseja <b class="text-danger">excluir</b> o grupo? uma
          vez excluido não será mais possivel visualiza-lo e seus arquivos serão
          <b class="text-danger">movidos</b> para a pasta
          <u class="text-danger">"Todos"</u>
        </template>

        <template #modal-footer="{ cancel }">
          <b-button variant="outline-secondary" @click="cancel()">
            Fechar
          </b-button>
          <b-button
            variant="danger"
            :disabled="loading"
            @click="removePdfGroup(true)"
          >
            Excluir
            <feather-icon
              v-if="loading"
              icon="TrashIcon"
              size="15"
              class="spinner"
            />
          </b-button>
        </template>
      </b-modal>

      <b-modal
        v-model="showDeleteExternalPDFModal"
        @hide="deleteExternalPdfId = null"
        id="modal-scoped"
        centered
      >
        <template #modal-header>
          <h5>Excluir PDF Externo</h5>
        </template>

        <template #default>
          Tem certeza que deseja <b class="text-danger">excluir</b> o pdf
          externo? uma vez excluido não será mais possivel visualiza-lo ou
          recuperar os seus dados
        </template>

        <template #modal-footer="{ cancel }">
          <b-button variant="outline-secondary" @click="cancel()">
            Fechar
          </b-button>
          <b-button
            variant="danger"
            :disabled="loading"
            @click="deleteExternalPdf(deleteExternalPdfId, true)"
          >
            Excluir
            <feather-icon
              v-if="loading"
              icon="TrashIcon"
              size="15"
              class="spinner"
            />
          </b-button>
        </template>
      </b-modal>
    </b-row>
  </b-card>
</template>

<script>
import VxTable from '@/components/vx-table/VxTable.vue';
import {
  BCard,
  BCardGroup,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BTab,
  BTabs,
  BButton,
  BButtonGroup,
  BFormInput,
  BFormFile,
  BTable,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import vSelect from 'vue-select';

export default {
  components: {
    VxTable,
    BCard,
    BCardGroup,
    BCardText,
    BCardBody,
    vSelect,
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    BButtonGroup,
    BFormInput,
    BFormFile,
    ToastificationContent,
    BTable,
    BPagination,
    BTooltip,
    BSpinner,
  },
  data() {
    return {
      totalRows: 1,
      isBusy: false,
      // currentPage: 1,
      tableKey: 0,
      cPage: 1,
      customPaginationItems: [],
      customPaginationTotalRows: 0,
      customPaginationSearch: '',
      customPaginationLoading: false,
      perPage: this.customPagination ? 10 : 50,
      pageOptions: [5, 10, 15, 50, { value: 100, text: '100' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      rows: 0,
      currentPage: 1,
      externalPdfGroup: {
        modal_new: false,
        modal_update: false,
        show_delete: false,
        selected_id: 'all',
        selected: null,
        last_selected: 'all',
        description: '',
        array: [],
        change_group: {
          modal: false,
          external_pdf_id: '',
          group_id: '',
        },
        expand: false,
      },
      client_id: '',
      showNewPDFModal: false,
      exports: false,
      modelNewPDF: {
        file: null,
        file_name: '',
        file_base: '',
        description: '',
        equipment_id: '',
        pdf_group_id: '',
        client_id: '',
      },
      clientsOptions: [],
      componentKey: 0,
      equipmentOptions: [],
      equipmentLoading: false,
      loading: false,
      externalItems: [],
      showDeleteExternalPDFModal: false,
      deleteExternalPdfId: null,
      items: [],
      itemsOptions: [
        {
          client_id: 1,
          client_name: 'Todos',
          description: 'Todos',
        },
      ],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
          sortDirection: 'desc',
          class: 'd-none text-center',
        },
        {
          key: 'index',
          label: 'Item',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'description',
          label: 'Descrição',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'equipname',
          label: 'Equipamento',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'cnpj',
          label: ' CNPJ/CPF',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'client_name',
          label: 'Estabelecimento',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'date',
          label: 'Data',
          sortable: true,
          sortDirection: 'asc',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'Ações',
          class: 'text-center',
        },
      ],
    };
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
    client_user() {
      return this.$store.getters.client_user;
    },
  },

  watch: {
    client_id(val) {
      this.filteredItems(val ? val.client_id : null);
    },
  },

  mounted() {
    this.getAllClientsOptions();
    if (!this.client_user) this.getCompanyClients();
    this.getPdfGroups();
  },
  methods: {
    handleGroupSelect(selected_id) {
      var last_element = document.getElementById(
        'folder' + this.externalPdfGroup.last_selected
      );
      if (last_element) last_element.classList.remove('group_selected');

      var current_element = document.getElementById('folder' + selected_id);
      current_element.classList.add('group_selected');

      this.externalPdfGroup.selected_id = selected_id;
      this.externalPdfGroup.last_selected = selected_id;

      this.externalPdfGroup.selected =
        selected_id == 'all' ? null : this.externalPdfGroup.array[selected_id];

      this.$nextTick(() => {
        this.$refs.externalPdfTable.getCustomPaginationPage(1);
      });
    },
    handleGroupOpen() {
      this.externalPdfGroup.expand = true;
    },
    getPdfGroups() {
      this.externalPdfGroup.array = [];
      this.$store
        .dispatch('getPdfGroups')
        .then((resp) => {
          this.externalPdfGroup.array = resp;
        })
        .catch((err) => {});
    },
    savePdfGroup() {
      this.loading = true;
      this.$store
        .dispatch('savePdfGroup', {
          description: this.externalPdfGroup.description,
        })
        .then((resp) => {
          this.loading = false;
          this.externalPdfGroup.modal_new = false;
          this.getPdfGroups();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Grupo cadastrado',
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível cadastrar o grupo',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    openDocument(path) {
      window.open(this.returnUrlPdf(path), '_blank');
    },
    returnUrlPdf(path) {
      const credentials = {
        accessKeyId: process.env.VUE_APP_S3_KEY,
        secretAccessKey: process.env.VUE_APP_S3_SECRET,
      };

      AWS.config.update({
        credentials: credentials,
        region: process.env.VUE_APP_S3_REGION,
      });

      const s3 = new AWS.S3();

      const presignedGETURL = s3.getSignedUrl('getObject', {
        Bucket: process.env.VUE_APP_S3_BUCKET,
        Key: path,
        Expires: 300,
      });

      return presignedGETURL;
    },
    updatePdfGroup(confirmed = false) {
      if (!confirmed) {
        this.externalPdfGroup.description =
          this.externalPdfGroup.selected.description;
        this.externalPdfGroup.modal_update = true;
      } else {
        this.loading = true;
        this.$store
          .dispatch('updatePdfGroup', {
            description: this.externalPdfGroup.description,
            pdf_group_id: this.externalPdfGroup.selected.id,
          })
          .then((resp) => {
            this.loading = false;
            this.externalPdfGroup.modal_update = false;
            this.externalPdfGroup.expand = false;
            this.getPdfGroups();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Grupo atualizado!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Não foi possível atualizar o grupo',
                text: `${error}`,
                icon: 'XIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    removePdfGroup(confirmed = false) {
      if (!confirmed) {
        this.externalPdfGroup.show_delete = true;
      } else {
        this.loading = true;
        this.$store
          .dispatch('removePdfGroup', this.externalPdfGroup.selected.id)
          .then((resp) => {
            this.loading = false;
            this.externalPdfGroup.show_delete = false;
            this.externalPdfGroup.expand = false;
            this.handleGroupSelect('all');
            this.getPdfGroups();
            this.$refs.externalPdfTable.getCustomPaginationPage(1);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'PDF externo excluido',
                icon: 'CheckIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            let errmsg = '';
            switch (error.response.data.message) {
              case 'group has data inside':
                errmsg = 'Existem documentos no grupo!!';
                break;
              default:
                errmsg = `${error}`;
            }
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Não foi possível excluir o grupo',
                text: errmsg,
                icon: 'XIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    setExtern(val) {
      this.exports = val;
    },
    deleteExternalPdf(item_id, confirmed = false) {
      if (!confirmed) {
        this.deleteExternalPdfId = item_id;
        this.showDeleteExternalPDFModal = true;
      } else {
        this.loading = true;
        this.$store
          .dispatch('removeExternalPDF', item_id)
          .then((resp) => {
            this.loading = false;
            this.deleteExternalPdfId = null;
            this.showDeleteExternalPDFModal = false;
            this.$refs.externalPdfTable.getCustomPaginationPage(1);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'PDF externo excluido',
                icon: 'CheckIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Não foi possível excluir o pdf',
                text: `${error}`,
                icon: 'XIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    setPaginated(val) {
      this.rows = val;
    },
    onFiltered(filteredItems) {
      this.setPaginated(filteredItems.length);
      this.currentPage = 1;
    },
    changeExternalPdfGroup(item, confirmed = false) {
      if (!confirmed) {
        this.externalPdfGroup.change_group.external_pdf_id = item.id;
        this.externalPdfGroup.change_group.modal = true;
      } else {
        this.loading = true;
        this.$store
          .dispatch(
            'changeExternalPdfGroup',
            this.externalPdfGroup.change_group
          )
          .then((resp) => {
            this.loading = false;
            this.externalPdfGroup.change_group.external_pdf_id = '';
            this.externalPdfGroup.change_group.group_id = '';
            this.externalPdfGroup.change_group.modal = false;
            this.$nextTick(() => {
              this.$refs.externalPdfTable.getCustomPaginationPage(1);
            });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Grupo do pdf atualizado',
                icon: 'CheckIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Não foi possível mudar grupo do pdf',
                text: `${error}`,
                icon: 'XIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    getCompanyClients() {
      this.clientsOptions = [];
      this.$store
        .dispatch('getCompanyClients')
        .then((resp) => {
          this.clientsOptions = resp.data;
        })
        .catch((err) => {});
    },
    getClientEquipments() {
      this.modelNewPDF.equipment_id = '';
      if (this.modelNewPDF.client_id) {
        this.equipmentLoading = true;
        this.$store
          .dispatch('getClientEquipments', {
            client_id: this.modelNewPDF.client_id,
            company_id: this.company.id,
          })
          .then((resp) => {
            this.equipmentOptions = resp.data;
            this.equipmentLoading = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Não foi possível carregar os equipamentos do cliente',
                text: `${error}`,
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            this.equipmentLoading = false;
          });
      }
    },
    insertExternalPDF() {
      this.loading = true;
      this.$store
        .dispatch('insertExternalPDF', this.modelNewPDF)
        .then((resp) => {
          this.loading = false;
          this.showNewPDFModal = false;
          this.$refs.externalPdfTable.getCustomPaginationPage(1);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'PDF externo cadastrado',
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível cadastrar o pdf',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    handleNewPDFModalClose() {
      this.$refs['file-input'].reset();
      this.modelNewPDF = {
        file: null,
        file_name: '',
        file_base: '',
        description: '',
        equipment_id: '',
        group_id: '',
        client_id: '',
      };
    },
    handleExternalPdfSelected() {
      this.modelNewPDF.file_base = '';
      this.modelNewPDF.file_name = '';
      if (this.modelNewPDF.file) {
        if (this.modelNewPDF.file.size <= 80000000) {
          this.getBase64(this.modelNewPDF.file).then((data) => {
            if (data.substring(0, 20) == 'data:application/pdf') {
              data = data.replace('data:', '').replace(/^.+,/, '');
              this.modelNewPDF.file_base = data;
              this.modelNewPDF.file_name = this.modelNewPDF.file.name.substring(
                0,
                this.modelNewPDF.file.name.lastIndexOf('.')
              );
            } else {
              this.$refs['file-input'].reset();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'O arquivo precisa ser do tipo PDF!',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            }
          });
        } else {
          this.$refs['file-input'].reset();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pro favor selecione apenas arquivos de no máximo 1MB!',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    filteredItems(val) {
      if (val == 1) {
        this.items = [];
        this.getAllClientsOptions();
      } else {
        this.items = [];
        this.$store
          .dispatch('getAllDocuments', {
            client_id: val,
            company_id: this.company.id,
          })
          .then((resp) => {
            resp.map((item) => {
              let objectItems = {
                id: item.order_service_form_id,
                description: item.form.description,
                equipname: item.equipment
                  ? item.equipment.description + ' - ' + item.equipment.model
                  : '--',
                path: item.path,
                cnpj: item.client.num_doc,
                date: this.dateFormatter(
                  item.order_service_form.created_at,
                  'DD/MM/yyyy'
                ),
                client_name: item.client.name,
                client_id: item.client.id,
              };
              this.items.push(objectItems);
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Não foi possível carregar os documentos',
                text: `${error}`,
                icon: 'XIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    async getPage(val) {
      this.items = [];
      this.isBusy = true;

      this.items = [];
      let body = {
        page: val,
      };

      try {
        await this.$store.dispatch('getAll', body).then(async (resp) => {
          let tempArr = [];

          await Promise.all(
            resp.data.map((item) => {
              let objectItens = {
                id: item.order_service_form_id,
                description: item.form.description,
                equipname: item.equipment ? item.equipment.description : '--',
                path: item.path,
                date: this.dateFormatter(
                  item.order_service_form.created_at,
                  'DD/MM/yyyy'
                ),
                cnpj: item.client.num_doc,
                client_name: item.client.name,
                client_id: item.client.id,
              };
              tempArr.push(objectItens);
            })
          );

          this.$nextTick(() => {
            this.currentPage = val;
            this.$set(this, 'items', [...tempArr]);

            this.isBusy = false;
          });
        });
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        this.isBusy = false;
      }
    },

    getAllClientsOptions() {
      const arrayTemp = [
        {
          client_id: 1,
          client_name: 'Todos',
          description: 'Todos',
        },
      ];
      this.$store
        .dispatch('getAll')
        .then((resp) => {
          this.rows = resp.total;
          const arrayUniqueByKey = [
            ...new Map(
              resp.data.map((item) => [item['client_id'], item])
            ).values(),
          ];
          arrayUniqueByKey.map((item) => {
            let objectItensOptions = {
              id: item.order_service_form_id,
              description: item.form.description,
              equipname: item.equipment ? item.equipment.description : '--',
              path: item.path,
              date: this.dateFormatter(
                item.order_service_form.created_at,
                'DD/MM/yyyy'
              ),
              cnpj: item.client.num_doc,
              client_name: item.client.name,
              client_id: item.client.id,
            };
            arrayTemp.push(objectItensOptions);
          });
          this.itemsOptions = arrayTemp;
          resp.data.map((item) => {
            let objectItens = {
              id: item.order_service_form_id,
              description: item.form.description,
              equipname: item.equipment ? item.equipment.description : '--',
              path: item.path,
              date: this.dateFormatter(
                item.order_service_form.created_at,
                'DD/MM/yyyy'
              ),
              cnpj: item.client.num_doc,
              client_name: item.client.name,
              client_id: item.client.id,
            };
            this.items.push(objectItens);
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar os documentos',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
<style scoped>
body {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -moz-text-size-adjust: none;
}

h1,
.muted {
  color: #2c3e5099;
}

h1 {
  font-size: 26px;
  font-weight: 600;
}

#app {
  max-width: 30em;
  margin: 1em auto;
}

.effects:hover {
  margin: -10px;
  cursor: pointer;
}

.effects:active {
  margin: 10px;
}

.effects > div > .card-body {
  padding: 0px;
  padding-left: 3px;
  padding-right: 3px;
  margin: 0px;
}

.group_selected {
  color: rgba(21, 113, 111, 1);
  border: 1px solid;
}

.group_selected > div > img {
  /* background-image: url('/img/folder.a6bdd8f8.svg') ; */
  background-color: rgba(21, 113, 111, 0.1);
  /* content: url('/img/folder_open.2cdc0ace.svg') */
}
</style>
